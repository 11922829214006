import apiAxios from './index'; // Importez votre client axios

export default async function requestTauxInteret() {
  try {
    const response = await apiAxios.get('/taux');

    return response; // Retournez les données pour les utiliser dans votre application
  }
  catch (err) {
    console.error('Erreur lors de la récupération des données : ', err);
    return err; // En cas d'erreur, retournez l'erreur
  }
}

import axios from 'axios';

const apiAxios = axios.create({
  baseURL: 'https://countercalculette.glitch.me',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // Active l'envoi des cookies
});

export default apiAxios;

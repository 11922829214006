import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  FaFacebook, FaTwitter, FaInstagram, FaHandHoldingHeart, FaEnvelope, FaEye,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import './footer.scss';
import { counterRequest, incrementVisitRequest } from '../../Request/CounterRequest';

function Footer({ hasConsented }) {
  const [modalContent, setModalContent] = useState(null);
  const [visitCount, setVisitCount] = useState(null);
  const [error, setError] = useState(null);

  // Effet pour gérer le compteur de visites
  useEffect(() => {
    if (hasConsented) {
      const getVisitCount = async () => {
        try {
          const data = await counterRequest();
          setVisitCount(data.visitCount);
        }
        catch (err) {
          console.error('Erreur lors de la récupération du compteur:', err);
          setError(err);
        }
      };

      const updateVisitCount = async () => {
        try {
          const count = await incrementVisitRequest(hasConsented);
          setVisitCount(count);
        }
        catch (err) {
          console.error('Erreur lors de l\'incrémentation du compteur:', err);
        }
      };

      updateVisitCount();
      getVisitCount();
    }
  }, [hasConsented]);

  const openModal = (content) => setModalContent(content);
  const closeModal = () => setModalContent(null);
  const currentYear = new Date().getFullYear();

  return (
    <>
      <footer className="footer">
        {/* Liens sociaux */}
        <div className="footer-socials">
          <a href="https://www.facebook.com" target="_blank" rel="nofollow noopener noreferrer" aria-label="Facebook">
            <FaFacebook />
          </a>
          <a href="https://www.twitter.com" target="_blank" rel="nofollow noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="nofollow noopener noreferrer" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="https://fr.tipeee.com/boite-a-outils" target="_blank" rel="nofollow noopener noreferrer" aria-label="Soutenez-nous sur Tipeee">
            <FaHandHoldingHeart />
          </a>
          <a href="mailto:6thfc@proton.me" target="_blank" rel="noopener noreferrer" aria-label="Envoyer un email">
            <FaEnvelope />
          </a>
        </div>

        {/* Liens légaux */}
        <div className="footer-links">
          <button type="button" onClick={() => openModal('CGU')}>Conditions générales d’utilisation</button>
          <button type="button" onClick={() => openModal('Politique')}>Politique de confidentialité</button>
        </div>

        {/* Compteur de visites */}
        {hasConsented && (
          <div className="footer-visit-counter">
            {error ? (
              <p>Erreur : {error.message}</p>
            ) : (
              <>
                <FaEye className="footer-visit-counter-FaEye" aria-label="Nombre de visites" />
                <span>{visitCount || 0} Visites</span>
              </>
            )}
          </div>
        )}

        {/* Copyright */}
        <p>© 2024-{currentYear} calculetteimmo.com Tous droits réservés.</p>
      </footer>

      {/* Modal pour CGU et Politique */}
      {modalContent && (
        <motion.div
          className="modal-backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={closeModal}
        >
          <motion.div
            className="modal-content"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
            onClick={(e) => e.stopPropagation()}
          >
            <h2>{modalContent === 'CGU' ? 'Conditions générales d’utilisation' : 'Politique de confidentialité'}</h2>
            <p>
              {modalContent === 'CGU' ? (
                <>
                  <strong>Conditions générales d'utilisation</strong>
                  <ol>
                    <li><strong>Acceptation des conditions :</strong> En utilisant ce site, vous acceptez les présentes conditions.</li>
                    <li><strong>Utilisation du site :</strong> Vous vous engagez à ne pas utiliser ce site de manière abusive ou illégale.</li>
                    <li><strong>Cookies :</strong> Nous utilisons des cookies pour mesurer le trafic. Consultez notre Politique de confidentialité pour plus d'informations.</li>
                    <li><strong>Responsabilité :</strong> Nous ne sommes pas responsables des éventuels dommages liés à l'utilisation de ce site.</li>
                    <li><strong>Avertissement financier :</strong> Les informations fournies sur ce site, y compris les articles, les exemples et les contenus pédagogiques, ne constituent en aucun cas des conseils financiers, d'investissement ou juridiques. Les contenus sont partagés à titre informatif et reflètent uniquement les expériences personnelles de l'auteur. Les investissements comportent des risques, et il est important de ne jamais investir plus que ce que vous pouvez vous permettre de perdre. Avant de prendre toute décision financière, nous vous recommandons de consulter un conseiller financier ou un professionnel qualifié, qui saura vous orienter en fonction de votre situation personnelle.</li>
                    <li><strong>Limitation de responsabilité :</strong> Le site et son contenu sont fournis "tels quels", sans aucune garantie expresse ou implicite. L'utilisateur reconnaît qu'il utilise les informations à ses propres risques et qu'aucune responsabilité ne pourra être engagée à l'encontre de l'auteur ou des responsables du site en cas de pertes financières ou de dommages découlant de l'utilisation des informations disponibles.</li>
                    <li><strong>Modification des conditions :</strong> Ces conditions peuvent être modifiées à tout moment. Nous vous conseillons de consulter régulièrement cette page pour prendre connaissance de toute mise à jour.</li>
                    <li><strong>Propriété intellectuelle :</strong> Tous les contenus présents sur ce site (textes, images, logos, etc.) sont protégés par des droits de propriété intellectuelle et ne peuvent être utilisés sans autorisation préalable.</li>
                    <li><strong>Collecte de données personnelles :</strong> Nous collectons des données personnelles conformément à notre Politique de confidentialité. En utilisant ce site, vous acceptez cette collecte et traitement de vos données personnelles.</li>
                    <li><strong>Loi applicable et juridiction :</strong> Les présentes conditions sont régies par la loi française. En cas de litige, les tribunaux français seront compétents.</li>
                  </ol>
                </>
              ) : (
                <>
                  <strong>Politique de confidentialité</strong>
                  <ol>
                    <li>Données collectées : Nous utilisons des cookies pour mesurer les visites via un compteur.</li>
                    <li>Objectif des données : Comprendre l'utilisation de notre site et l'améliorer.</li>
                    <li>Désactivation des cookies : Configurez votre navigateur pour désactiver les cookies.</li>
                    <li>Contact : Pour toute question, écrivez-nous à <a href="mailto:6thfc@proton.me">6thfc@proton.me</a>.</li>
                  </ol>
                </>
              )}
            </p>
            <button type="button" onClick={closeModal}>Fermer</button>
          </motion.div>
        </motion.div>
      )}
    </>
  );
}

Footer.propTypes = {
  hasConsented: PropTypes.bool.isRequired,
};

export default Footer;

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { motion } from 'framer-motion';
import './CookieConsent.scss';

function CookieConsent({ onConsentChange }) {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // Vérifie si l'utilisateur a déjà accepté les cookies
    const hasAcceptedCookies = Cookies.get('cookies_accepted');

    if (!hasAcceptedCookies) {
      // Si le cookie n'a pas été accepté, afficher la modale
      setShowModal(true);
    }
    else {
      // Informer le parent de l'état du consentement
      onConsentChange(hasAcceptedCookies === 'true');
    }
  }, []);

  const handleAcceptCookies = () => {
    // Définir un cookie pour mémoriser que l'utilisateur a accepté les cookies
    Cookies.set('cookies_accepted', 'true', { expires: 1 });
    onConsentChange(true);
    setShowModal(false);
  };

  const handleRejectCookies = () => {
    // Si l'utilisateur refuse, vous pouvez soit ne rien faire, soit définir un cookie pour indiquer qu'il a refusé
    Cookies.set('cookies_accepted', 'false', { expires: 1 });
    onConsentChange(false);
    setShowModal(false);
  };

  return (
    <>
      {/* Modale pour accepter les cookies */}
      {showModal && (
        <motion.div
          className="cookie-modal-backdrop"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="cookie-modal"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0.8 }}
          >
            <h2>Nous utilisons des cookies</h2>
            <p>
              Ce site utilise des cookies pour améliorer l'expérience utilisateur et analyser le trafic.
              En cliquant sur "Accepter", vous consentez à l'utilisation de cookies.
            </p>
            <div className="cookie-buttons">
              <button type="button" onClick={handleAcceptCookies}>Accepter</button>
              <button type="button" onClick={handleRejectCookies}>Refuser</button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </>
  );
}
CookieConsent.propTypes = {
  onConsentChange: PropTypes.func.isRequired,
};

export default CookieConsent;

import apiAxios from './index';

export async function counterRequest() {
  try {
    const response = await apiAxios.get('/get-visit-count');
    return response.data; // Retourne uniquement les données pertinentes
  }
  catch (err) {
    console.error('Erreur dans counterRequest:', err.message);
    throw err; // Propager l'erreur pour qu'elle soit gérée dans le composant
  }
}
export async function incrementVisitRequest(hasConsented) {
  try {
    const response = await apiAxios.post(
      '/increment-visit',
      {
        hasConsented: { hasConsented },
      },
    );
    return response.data.visitCount; // Retourne le compteur mis à jour
  }
  catch (err) {
    console.error('Erreur dans incrementVisitRequest:', err.message);
    throw err;
  }
}

import PropTypes from 'prop-types';
import { useState, useEffect, useRef } from 'react';

function Rentabilite({ formValues }) {
  console.log('dans rentabilite', { formValues, type: typeof formValues });
  const [resultat, setResultat] = useState(null);
  const [mensualiteEmprunt, setMensualiteEmprunt] = useState(null);
  const [rentabiliteBrute, setRentabiliteBrute] = useState(null);
  const [rentabiliteNette, setRentabiliteNette] = useState(null);
  const resultatRef = useRef(null); // Référence pour le focus

  const {
    PrixAchat,
    apport,
    tauxEmprunt,
    dureeCredit,
    loyer,
    chargesRecuperables,
    chargesCopropriete,
    taxeFonciere,
    entretien,
    assurance,
    coutRenovation,
    vacanceLocative,
  } = formValues;

  useEffect(() => {
    // Montant emprunté
    const montantEmprunte = PrixAchat - apport;

    // Mensualité du crédit
    const tauxMensuel = tauxEmprunt / 100 / 12;
    const totalMois = dureeCredit * 12;
    const mensualiteCalculée = (
      montantEmprunte * tauxMensuel * ((1 + tauxMensuel) ** totalMois)
    ) / (((1 + tauxMensuel) ** totalMois) - 1);

    setMensualiteEmprunt(mensualiteCalculée);

    // Vacance locative mensuelle
    const coutVacanceLocative = ((loyer + chargesRecuperables) * (vacanceLocative / 100)) / 12;

    // Coût de la rénovation mensuel
    const coutRenovationMensuel = coutRenovation / totalMois;

    // Dépenses mensuelles
    const depensesTotales = mensualiteCalculée
      + chargesCopropriete
      + taxeFonciere
      + entretien
      + assurance
      + coutVacanceLocative
      + coutRenovationMensuel
      - chargesRecuperables;

    // Dépenses mensuelles sans tenir compte du crédit immobilier
    const depensesTotalesSansLePret = chargesCopropriete
      + taxeFonciere
      + entretien
      + assurance
      + coutVacanceLocative
      + coutRenovationMensuel
      - chargesRecuperables;

    // Revenus locatifs nets
    const revenusNets = loyer;

    // Rentabilité brute
    const loyerAnnuel = loyer * 12;
    const rentabiliteBruteCalculée = (loyerAnnuel / PrixAchat) * 100;
    console.log('rentabiliteBruteCalculée', rentabiliteBruteCalculée);
    setRentabiliteBrute(rentabiliteBruteCalculée);

    // Rentabilité nette
    const revenusNetsAnnuel = revenusNets * 12;
    const rentabiliteNetteCalculée = (((revenusNetsAnnuel - (depensesTotalesSansLePret * 12))) / PrixAchat) * 100;
    setRentabiliteNette(rentabiliteNetteCalculée);
    console.log('rentabiliteNetteCalculée', rentabiliteNetteCalculée);

    // Effort net mensuel
    const effortNet = revenusNets - depensesTotales;
    setResultat(effortNet);

    // Scroller vers la div des résultats
    setTimeout(() => {
      resultatRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [
    PrixAchat,
    apport,
    tauxEmprunt,
    dureeCredit,
    loyer,
    chargesRecuperables,
    chargesCopropriete,
    taxeFonciere,
    entretien,
    assurance,
    coutRenovation,
    vacanceLocative,
  ]);

  return (
    <div ref={resultatRef}>
      <h1>Calcul de Rentabilité</h1>
      {/* <p>Mensualité : {mensualiteEmprunt?.toFixed(0)} €</p>
      <p>Rentabilité brute : {rentabiliteBrute?.toFixed(1)} %</p>
      <p>Rentabilité nette : {rentabiliteNette?.toFixed(1)} %</p>
      <p>Effort net mensuel : {resultat?.toFixed(0)} €</p> */}
      {mensualiteEmprunt !== null && (
        <div>
          <h3>Mensualité du prêt : {mensualiteEmprunt.toFixed(0)} €</h3>
        </div>
      )}
      {rentabiliteBrute !== null && (
        <div>
          <h3>Rentabilité brute : {rentabiliteBrute.toFixed(1)}%</h3>
        </div>
      )}
      {rentabiliteNette !== null && (
        <div>
          <h3>Rentabilité nette : {rentabiliteNette.toFixed(1)}%</h3>
        </div>
      )}
      {resultat !== null && (
      <div>
        <h3>
          {resultat >= 0
            ? `Bénéfice net mensuel : ${resultat.toFixed(0)} €`
            : `Effort net mensuel : ${Math.abs(resultat).toFixed(0)} €`}
        </h3>
      </div>
      )}
    </div>
  );
}

// Validation des props avec PropTypes
Rentabilite.propTypes = {
  formValues: PropTypes.shape({
    PrixAchat: PropTypes.number.isRequired,
    apport: PropTypes.number.isRequired,
    tauxEmprunt: PropTypes.number.isRequired,
    dureeCredit: PropTypes.number.isRequired,
    loyer: PropTypes.number.isRequired,
    chargesRecuperables: PropTypes.number.isRequired,
    chargesCopropriete: PropTypes.number.isRequired,
    taxeFonciere: PropTypes.number.isRequired,
    entretien: PropTypes.number.isRequired,
    assurance: PropTypes.number.isRequired,
    coutRenovation: PropTypes.number.isRequired,
    vacanceLocative: PropTypes.number.isRequired,
  }).isRequired,
};

export default Rentabilite;

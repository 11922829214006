import { useEffect, useRef } from 'react';
import './adsenseAd.scss';

function AdSenseAd() {
  const adRef = useRef(null); // Référence à la div contenant l'annonce

  useEffect(() => {
    // Initialise les annonces après le montage du composant
    if (window.adsbygoogle && adRef.current) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []); // S'exécute une seule fois

  return (
    <div className="adsense-ad">
      <ins
        className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-9574641219746800" // Remplacez par votre ID AdSense
        data-ad-slot="1234567890" // Remplacez par un ID d'emplacement valide
        data-ad-format="auto"
      />
    </div>
  );
}

export default AdSenseAd;
